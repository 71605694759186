<template>
  <div class="city-add main-block col-lg-6">
    <form action="" @submit.prevent="save()">
      <div class="city-add__box main-block__head">
        <h1 class="city-add__box-title main-block__title">Информация</h1>
        <button class="main-block__btn default-btn" type="submit">Сохранить</button>
      </div>
      <ul class="city-add__items">
        <li class="city-add__item flex">
          <p class="city-add__key">Название</p>
          <input
            v-model="name"
            type="text"
            class="city-add__value city-add__value_align"
            placeholder=""
          />
        </li>
        <li class="city-add__item flex">
          <p class="city-add__key">Тип</p>
          <b-form-select v-model="selectedCityType" :options="cityTypeOptions" class="w-75 mw-50" />
        </li>
        <!--        <li v-if="regionList" class="city-add__item flex">-->
        <!--          <p class="city-add__key">Регион</p>-->
        <!--          <b-form-select-->
        <!--            class="city-add__value main__select"-->
        <!--            v-model="regionId"-->
        <!--            :options="filterRegionList"-->
        <!--            text-field="title"-->
        <!--            value-field="id"-->
        <!--            required-->
        <!--          >-->
        <!--          </b-form-select>-->
        <!--          <img-->
        <!--            class="main__select-icon"-->
        <!--            src="@/assets/png/select-icon.png"-->
        <!--            alt=""-->
        <!--          />-->
        <!--        </li>-->
        <li class="city-add__item flex">
          <p class="city-add__key">Добавить кросс-докинг</p>
          <b-form-checkbox v-model="mapShow" class="city-add__checkbox" />
        </li>
        <li v-if="mapShow" class="city-add__item flex">
          <p class="city-add__key">Введите адрес кросс-докинга</p>
          <input
            v-model="addressCross"
            type="text"
            class="city-add__value city-add__value_align"
            placeholder=""
            @input="searchAddress()"
          />
          <ul v-if="searchShow" class="city-add__search-list">
            <li
              v-for="(item, index) in searchList"
              :key="index"
              class="city-add__search-item"
              @click="
                (searchItemClick = item.GeoObject.Point.pos.split(' ')),
                  (searchShow = false),
                  handleSearchItemClick()
              "
            >
              {{ item.GeoObject.name }}, {{ item.GeoObject.description }}
            </li>
          </ul>
        </li>
        <!--        <li class="city-add__item flex">-->
        <!--          <p class="city-add__key">Количество машин</p>-->
        <!--          <input-->
        <!--            type="number"-->
        <!--            v-model="countTransport"-->
        <!--            class="city-add__value city-add__value_align"-->
        <!--            placeholder=""-->
        <!--            required-->
        <!--          />-->
        <!--        </li>-->

        <!--        <li v-if="mapShow" class="city-add__item flex">-->
        <!--          <p class="city-add__key">Lat</p>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            v-model="latCross"-->
        <!--            class="city-add__value city-add__value_align"-->
        <!--            placeholder=""-->
        <!--          />-->
        <!--        </li>-->
        <!--        <li v-if="mapShow" class="city-add__item flex">-->
        <!--          <p class="city-add__key">Long</p>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            v-model="longCross"-->
        <!--            class="city-add__value city-add__value_align"-->
        <!--            placeholder=""-->
        <!--          />-->
        <!--        </li>-->
        <!--        <template v-if="transportList">-->
        <!--          <li v-for="(transport, index) in transports" :key="index" class="city-add__item flex">-->
        <!--            <p class="city-add__key">Транспорт {{ index + 1 }}</p>-->
        <!--            <b-form-select-->
        <!--              class="city-add__value main__select"-->
        <!--              v-model="transport.id"-->
        <!--              :options="filterTransportList"-->
        <!--              text-field="name"-->
        <!--              value-field="id"-->
        <!--            >-->
        <!--            </b-form-select>-->
        <!--            <img-->
        <!--              class="main__select-icon"-->
        <!--              src="@/assets/png/select-icon.png"-->
        <!--              alt=""-->
        <!--            />-->
        <!--          </li>-->
        <!--        </template>-->
        <!--        <button @click="addTransport()" class="main-block__btn default-btn" type="button">Добавить транспорт</button>-->
        <!--        <button @click="removeTransport()" class="btn danger-btn" type="button">Удалить транспорт</button>-->
      </ul>
      <MapBlock
        v-if="mapShow"
        class="city-add__map"
        :search-click="searchItemClick"
        :get-coords="[latCross, longCross]"
        @coords="coords = $event"
        @address="addressCross = $event"
        :cityDeliveryZones="cityDeliveryZones"
      />
      <ul v-if="mapShow">
        <li class="city-add__item item-multiselect flex">
          <p class="city-add__key">Выбор города</p>
          <multiselect
            v-model="cityId"
            class="city-add__multiselect"
            :options="filterCitiesProducts"
            label="name"
            track-by="id"
            :multiple="true"
            placeholder="Выбор города"
          />
        </li>
      </ul>
    </form>
    <div v-if="searchShow" class="main-close" @click="searchShow = false" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      name: "",
      selectedCityType: null,
      regionId: null,
      addressCross: null,
      coords: null,
      countTransport: "",
      latCross: 50.5154206097806,
      longCross: 70.38807709504779,
      transports: [],
      mapShow: false,
      searchShow: false,
      searchList: null,
      searchItemClick: null,
      cityId: [],
      cityTypeOptions: [
        { value: null, text: "Выберите тип" },
        { value: "city", text: "Город" },
        { value: "village", text: "Село" },
        { value: "township", text: "Поселок" },
        { value: "rural district", text: "Cельский округ" },
      ],
      cityDeliveryZones: [],
    };
  },
  computed: {
    ...mapState(["regionList", "transportList", "citiesProducts", "CityDeliveryZones"]),
    filterRegionList() {
      return [{ id: null, title: "Выберите регион" }, ...(this.regionList?.data ?? undefined)];
    },
    filterCitiesProducts() {
      if (this.citiesProducts && this.citiesProducts.length > 0) {
        return [...this.citiesProducts];
      }
      return [{ id: null, name: "Выберите город" }];
    },
  },
  watch: {
    mapShow() {
      if (!this.mapShow) {
        // this.addressCross = ''
        // this.coords = []
      }
    },
  },
  async created() {
    // if (this.$route.query.id) {
    //   await this.$store.dispatch('getCitiesProducts')
    // }
    await this.$store.dispatch("getRegionList");
    await this.$store.dispatch("getTransportList");
    await this.$store.dispatch("getCityDeliveryZones", this.$route.query.id).then(() => {
      this.cityDeliveryZones = this.CityDeliveryZones;
    });
    // await this.$store.dispatch('getCitiesProducts')
  },
  async mounted() {
    if (this.$route.query.id) {
      await this.$store.dispatch("getCitiesProducts");
      const city = this.citiesProducts.find((city) => +city.id === +this.$route.query.id);
      if (city) {
        this.transports = [];
        this.name = city.name;
        this.cityId = this.citiesProducts.filter((e) =>
          city.cross_cities_id.some((city) => e.id === city)
        );
        this.regionId = city.region?.id;
        this.addressCross = city.cross_dockings?.address || "";
        this.latCross = city.cross_dockings?.lat || 50.5154206097806;
        this.longCross = city.cross_dockings?.long || 70.38807709504779;
        this.mapShow = !!city.is_cross;
        // city.tranports?.forEach((e) => {
        //   this.transports.push({ id: e.id})
        // })
      }
    }
  },
  methods: {
    async searchAddress() {
      await axios
        .get(
          `https://geocode-maps.yandex.ru/1.x/?apikey=c2ed0336-3aa7-4167-9030-608c1ecbf17c&format=json&geocode=${this.addressCross}&lang=ru_RU`
        )
        .then((response) => {
          this.searchList = response.data.response.GeoObjectCollection.featureMember;
        });
      if (this.searchList.length > 0) {
        this.searchShow = true;
      } else {
        this.searchShow = false;
      }
    },
    handleSearchItemClick() {
      console.log("this.searchItemClick :>> ", this.searchItemClick);
    },
    changeActive(id, value) {
      this.$api
        .post("/web/city-active", {
          city_id: id,
          is_active: value,
        })
        .then(() => {
          this.$toast.success("Успешно!");
          // this.$store.dispatch('getCitiesProducts')
        })
        .catch((e) => {
          this.$toast.error(e.response.data);
        });
    },
    addTransport() {
      this.transports.push({ id: null });
    },
    removeTransport() {
      this.transports.pop({ id: null });
    },
    save() {
      // if(this.mapShow) {
      //   this.changeActive(this.$route.query.id, true)
      // } else {
      //   this.changeActive(this.$route.query.id, false)
      // }
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("is_cross", this.mapShow ? 1 : 0);
      // formData.append('region_id', this.regionId)
      formData.append("address_cross", this.addressCross);
      formData.append("type", this.selectedCityType);
      // formData.append('count_transport', this.transports.length)
      if (this.coords && this.coords.length) {
        formData.append("lat_cross", this.coords[0]);
        formData.append("long_cross", this.coords[1]);
      }
      this.cityId.forEach((e, i) => {
        formData.append(`cities[${i}]`, e.id);
      });
      if (this.$route.query.id) formData.append("id", this.$route.query.id);
      this.transports.forEach((transport, index) => {
        formData.append(`transports[${index}]`, transport.id);
      });
      this.$api
        .post(this.$route.query.id ? "/web/edit-city" : "/web/add-city", formData)
        .then(() => {
          this.$router.push("/database/cities");
          this.$store.dispatch("getCitiesProducts");
        })
        .catch((e) => {
          this.$toast.error(e.response?.data?.message);
        });
    },
  },
  components: {
    MapBlock: () => ({
      component: import("@/components/map/map-city.vue"),
    }),
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.city-add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}
.item-multiselect:deep(.multiselect__tag) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight) {
  background-color: var(--secondary-color);
}
.item-multiselect:deep(.multiselect__option--highlight::after) {
  background-color: var(--secondary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected) {
  background-color: var(--tertiary-color);
}
.item-multiselect :deep(.multiselect__option--highlight.multiselect__option--selected::after) {
  background-color: var(--tertiary-color);
}
.city-add__item {
  padding-top: 11.5px;
  min-height: 27.5px;
  position: relative;
}
.city-add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}
.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}
.city-add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}
.city-add__value {
  width: 50%;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}
.city-add__value_align {
  text-align: end;
}
.city-add__checkbox input {
  cursor: pointer;
}
.city-add__map {
  margin-top: 24px;
  height: 300px;
  width: 100%;
}
.city-add__search-list {
  width: 50%;
  max-height: 250px;
  padding: 10px;
  border-radius: 0px 0px 20px 20px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(16, 51, 115, 0.08);
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 2;
}
.city-add__search-item {
  padding: 10px 5px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
}
.city-add__search-item:not(:last-child) {
  border-bottom: 1px solid #b4b4b4;
}
.city-add__search-item:hover {
  background-color: rgba(203, 206, 225, 0.71);
}
.city-add__multiselect {
  width: 50%;
  margin-top: 16px;
}
</style>
